import { useState, useEffect } from 'react';
import fetchWrapper from '../utils/fetch/fetchWrapper';
import isIE from '../utils/isIE';

const useZipcodeData = (zipcode) => {
  const [userLocation, setUserLocation] = useState(null);
  const [userLocationNotFound, setUserLocationNotFound] = useState(false);
  const [zipcodeUpdating, setZipcodeUpdating] = useState(true);
  const [financialData, setFinancialData] = useState({
    interestRateAsBasisPoints: null,
    salesTaxValue: null,
    electricityRate: null,
    evRegistrationFeesRegion: null,
    gasolinePriceInCentsPerGal: null,
  });

  useEffect(() => {
    const fetchData = async () => {
      const params = {
        postcode: zipcode, 
        nearby_distance: isIE()  ? 25 : 100, 
      };
      let url = new URL(`${process.env.REACT_APP_API_HOST}/location`);
  
      let searchParams = new URLSearchParams(params);
  
      url.search = searchParams;

      fetchWrapper(url, {
        method: 'GET',
      })
        .then((data) => {
          if (data.location) {
            const interestRateAsBasisPoints =
              data.location.national_financial_references[0]?.rates?.sofr * 100;
            const salesTaxValue =
              data.location.regional_financial_references[0]?.sales_tax
                .combined ?? null;
            const electricityRate =
              (data.location.regional_fuel_cost[0]?.electricity).toFixed(4) ??
              null;
            const evRegistrationFeesRegion =
              data.location.regional_financial_references[0]
                ?.ev_registration_fees?.region ?? 0;
            const gasolinePriceInCentsPerGal =
              data.location.regional_fuel_cost[0]?.gasoline * 100;

            setUserLocation(data.location);
            setUserLocationNotFound(false);
            setZipcodeUpdating(false);
            setFinancialData({
              interestRateAsBasisPoints,
              salesTaxValue,
              electricityRate,
              evRegistrationFeesRegion,
              gasolinePriceInCentsPerGal,
            });
          } else {
            setUserLocationNotFound(true);
            setZipcodeUpdating(false);
          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
          setUserLocationNotFound(true);
          setZipcodeUpdating(false);
        });
    };

    fetchData();
  }, [zipcode]);

  return { userLocation, userLocationNotFound, zipcodeUpdating, financialData, setZipcodeUpdating };
};

export default useZipcodeData;
