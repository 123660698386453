import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
const apiKey =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_PRODUCTION_TOKEN
    : process.env.REACT_APP_STAGING_TOKEN;

export const unifiedApi = createApi({
  baseQuery: fetchBaseQuery({
      reducerPath: 'unifiedAPI',
      baseUrl: process.env.REACT_APP_ENVIRONMENT === "production" ? 'https://app.production.zappyride.com' : 'https://app.beta.zappyride.com',
      prepareHeaders: (headers) => {
        headers.set('X-Api-Key', apiKey);
        return headers;
    }
  }),
  endpoints: (build) => {
    return {
      getVehicle: build.query({
        query: ({handle, postcode}) => {
          return `vehicles/${handle.handle}?postcode=${postcode}`;
        }
      }),
      getLocation: build.query({
        query: ({postcode}) => `location?postcode=${postcode}`
      }),
  }},
})

export const { 
  useGetVehicleQuery, 
  useGetLocationQuery 
} = unifiedApi;
