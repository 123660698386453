import React, {  useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import MonthlyFuelComparison from '../CostCharts/MonthlyFuelComparison/MonthlyFuelComparison';
import CostToOwnComparison from '../CostCharts/CostToOwnComparison';
import {
  SALES_TAX,
  GASOLINE_PRICE_IN_DOLLARS_PER_GAL,
  DOWN_PAYMENT,
  ELECTRICITY_RATE_IN_DOLLARS_PER_KWH,
} from '../../../client_customizations/data/assumptions/ASSUMPTIONS';

import { useIntl } from 'react-intl';
import BreakevenChart from '../../../components/CostsCharts/BreakevenChart';
import fetchWrapper from '../../../utils/fetch/fetchWrapper';
import './CostOfOwnership.scss';
import { useWidget } from '../../../context/widgetContext';

const CostOfOwnership = ({ cars, title, comparison, usedEv, location }) => {
  const intl = useIntl();
  const { userPrefs } = useWidget()
  const [isLoading, setIsLoading] = useState(true);
  const [insuranceData, setInsuranceData] = useState(null);
  const [maintenanceData, setMaintenanceData] = useState(null);

  useEffect(() => {
    const loadInsuranceData = async (vehicle_handle) => {
      const params = {
        postcode: userPrefs.get('zipcode'),
      };
      let url = new URL(
        `${process.env.REACT_APP_API_HOST}/vehicles/${vehicle_handle}/insurance`
      );
      let searchParams = new URLSearchParams(params);
      url.search = searchParams;
      try {
        const insuranceData = await fetchWrapper(url.toString(), {
          method: 'GET',
        });

        setInsuranceData(insuranceData.yearly_insurances);
        setIsLoading(false);
      } catch (error) {
        console.log('Failed to load insurance data.');
        console.log(error);
        setIsLoading(false);
      }
    };

    const loadMaintenanceData = async (vehicle_handle) => {
      const params = {
        annualmiles: userPrefs.get('milesDrivenAnnually'),
        postcode: userPrefs.get('zipcode'),
      };
      let url = new URL(
        `${process.env.REACT_APP_API_HOST}/vehicles/${vehicle_handle}/maintenance`
      );
      let searchParams = new URLSearchParams(params);
      url.search = searchParams;
      try {
        const maintenanceData = await fetchWrapper(url.toString(), {
          method: 'GET',
        });

        setMaintenanceData(maintenanceData.maintenance.costs);
        setIsLoading(false);
      } catch (error) {
        console.log('Failed to load insurance data.');
        console.log(error);
        setIsLoading(false);
      }
    };

    if (cars && cars.length > 0) {
      loadInsuranceData(cars[0].handle);
      loadMaintenanceData(cars[0].handle);
    }
  }, [cars, userPrefs]);

  if (!cars || cars.length === 0) return null;

  SALES_TAX.title = intl.formatMessage({
    id: 'assumption.salesTax',
    defaultMessage: 'Sales Tax',
  });
  GASOLINE_PRICE_IN_DOLLARS_PER_GAL.title = intl.formatMessage({
    id: 'assumption.gasolinePrice',
    defaultMessage: 'Price of Gasoline',
  });
  DOWN_PAYMENT.title = intl.formatMessage({
    id: 'assumption.downPayment',
    defaultMessage: 'Down Payment',
  });
  DOWN_PAYMENT.valueAsFormatted = intl.formatMessage({
    id: 'assumption.downPaymentValue',
    defaultMessage: '10% of MSRP + Tax',
  });
  ELECTRICITY_RATE_IN_DOLLARS_PER_KWH.title = intl.formatMessage({
    id: 'assumption.electricityRate',
    defaultMessage: 'Electricity Rate',
  });
  ELECTRICITY_RATE_IN_DOLLARS_PER_KWH.valueAsFormatted = process.env
    .REACT_APP_DYNAMIC_ELECTRIC_RATE
    ? `$${userPrefs.get('electricityRate')}/kWh`
    : ELECTRICITY_RATE_IN_DOLLARS_PER_KWH.valueAsFormatted;

  let salesTaxElecInfo = userPrefs.get('selectSalesTaxElecRate');
  for (let zip in salesTaxElecInfo) {
    if (location && zip === location.postcode) {
      ELECTRICITY_RATE_IN_DOLLARS_PER_KWH.value = salesTaxElecInfo[zip][1];

      ELECTRICITY_RATE_IN_DOLLARS_PER_KWH.valueAsFormatted =
        '$' +
        Math.round(userPrefs.get('electricityRate') * 100000) / 100000 +
        '/kWh';
    }
  }

  return (
       <section id="CostOfOwnership">
              {process.env.REACT_APP_PAGES_HOME_GRAPH_COST_TO_FILL_ENABLED && !isLoading && insuranceData && maintenanceData ? (
                     <MonthlyFuelComparison
                     cars={cars}
                     comparison={comparison}
                     insuranceData={insuranceData}
                     />
              ) : null}
              {process.env.REACT_APP_PAGES_HOME_GRAPH_COST_TO_OWN_COMPARISON && !isLoading && insuranceData ? (
                     <CostToOwnComparison
                            cars={cars}
                            hasExtraPadding
                            comparison={comparison}
                            insuranceData={insuranceData}
                            maintenanceData={maintenanceData}
                     />
              ) : null}
              {process.env.REACT_APP_PAGES_BREAKEVEN_GRAPH && !isLoading && insuranceData ? (
                     <BreakevenChart cars={cars} insuranceData={insuranceData} />
              ) : null}
    </section>
  );
};

export default CostOfOwnership;

CostOfOwnership.propTypes = {
  car: PropTypes.object,
};
